import logo from '../../assets/images/logo_name.svg';
import iconWp from '../../assets/images/icon_whatsapp.svg'
import underConstruction from '../../assets/images/under_construction.svg'
import './underConstruction.css'

function UnderConstruction() {
  return (
    <div className='container-under-construction'>
      <div className="under-construction">
        <div className='logo'>
          <img className='img-logo-name' alt={'logo-name'} src={logo} />
        </div>
        <div className='info'>
          <div className='text'>
            <span className='title'>Lo sentimos, estamos en<br /> mantenimiento.</span><br />
            <span className='p1'>Trabajamos en mejoras de nuestro sitio web<br />para un mejor servicio.</span><br />
            <span className='text-contact'>Realiza tus preguntas y cotizaciones por<br />medio de nuestra linea de WhatsApp</span><br />
            <a className='button' href={'https://wa.link/ql5roc'}><img alt='whatsapp' src={iconWp} className='wp-icon'/>Contáctanos</a>
          </div>
            <div className='under-construction'>
              <img className='img-under-construction' alt='under-construction' src={underConstruction}></img>
            </div>
        </div>
      </div>
    </div>
  );
}

export default UnderConstruction;
